import { Link } from "gatsby";
import React from "react";

const Map = ({
  heading,
  paragraph1,
  paragraph2,
  list = [],
  mandatoryLink,
  linkText,
}) => {
  console.log("List information ", list);
  return (
    <div className="gnx__map-component-wrapper goken-gray-text">
      <div className="map-wrapper">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3246.748970561424!2d139.63001211142677!3d35.53520063786777!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60185fd460937de7%3A0x633a571d566289d1!2zR29rZW5OSUhPTuagquW8j-S8muekvg!5e0!3m2!1sen!2sin!4v1711819563944!5m2!1sen!2sin"
          style={{ border: 0 }}
          // allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="gnx__paragraph-wrapper m-top-20px">
        {paragraph1 ? (
          <p className="para  margin-0 mt-3em">{paragraph1}</p>
        ) : null}
        {paragraph2 ? (
          <p className="para  margin-0 mb-3em">{paragraph2}</p>
        ) : null}
      </div>
    </div>
  );
};

export default Map;
