import React, { useState } from "react";
import Map from "./Map";
import axios from "axios";
import { navigate } from "gatsby";

const ContactUs = ({
  heading,
  paragraph,
  placeholder,
  link,
  linkText,
  paragraph1,
  paragraph2,
  comapnyHeading,
}) => {
  const [gnxEmail, setGnxEmail] = useState();
  const [load, setLoad] = useState(false);
  const [error, setError] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    setError("");
    try {
      let ipBox = document.getElementById("gnx-email");
      // REMOVE THE IP BOX BORDER
      ipBox.style.borderColor = "transparent";
    } catch (error) {}
    if (!gnxEmail?.trim()) {
      setError("このフォームに記入してください");
      try {
        let ipBox = document.getElementById("gnx-email");
        // REMOVE THE IP BOX BORDER
        ipBox.style.borderColor = "red";
        ipBox.style.borderWidth = "2px";
        // ipBox.style.color = "red";
      } catch (error) {}
      return;
    }
    setLoad(true);
    const params = new URLSearchParams();
    // let server = "http://localhost:8000";
    let server = "https://contactus.goken-global.com";
    params.append("email", gnxEmail);
    params.append("type", "GNX Inquiry");
    const configs1 = {
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
    };
    axios
      .post(server + "/webapp/file-save", params, configs1)
      .then((response) => {
        console.log(response.data.message);
        // setMessage(response.data.message);
        // setLoad(false);
        navigate("/thankyou/");
        setLoad(false);

        // setShowSubmission(true);
      })
      .catch((Error) => {
        console.log(Error);
        // setShowError(true);
        // setError("Something Went Wrong. Please try again after sometime.");
        setLoad(false);
      });
  };
  return (
    <div className="gnx__contact-us-map-wrapper pad-bottom-3rem w-80-wrapper goken-gray-text">
      <div className="gnx__contactus-wrapper">
        <div className="cta-content-wrapper  ">
          <h2 className="semi-page-heading fw-700 titlecase-text margin-0">
            {heading}
          </h2>

          <p className="para margin-0">{paragraph1}</p>
          <p className="para margin-0">{paragraph2}</p>
          <div className="cta-information-wrapper">
            <form className="cta-link" onSubmit={submitHandler}>
              <input
                type="email"
                className="cta-email-input"
                placeholder={placeholder}
                value={gnxEmail}
                onChange={(e) => {
                  setGnxEmail(e.target.value);
                }}
                id="gnx-email"
              />
              <button
                className={`bg-ja btn contact-button semi-title ${
                  load ? "j-start" : ""
                }`}
                type="submit"
              >
                {linkText} {load && <div className="loader loader-ja"></div>}
              </button>
            </form>
            {error && (
              <div className="error-text color-red m-top-10px">{error}</div>
            )}
          </div>
        </div>
        {/* <div className="gnx__map-wrapper">
          <div className="mobile-service-image map-title">
            <h2 className="semi-page-heading fw-700">{comapnyHeading}</h2>
          </div>
          <Map paragraph1={paragraph1} paragraph2={paragraph2} />
        </div> */}
      </div>
    </div>
  );
};

export default ContactUs;
