import React from "react";
import { Fade } from "react-reveal";

const PageHeader = ({
  headingOne = "",
  headingTwo = "",
  first = {},
  second = {},
  bgImage,
  mobileImage,
  secondHeadingClass = "color-in",
  hideImageGradient = false,
}) => {
  return (
    <div className="gnx__page-header nx__header-wrapper">
      <div className="gnx-desktop-wrapper">
        <div className="image-wrapper">
          <img src={bgImage} alt="GokenNIHON header image" />
          <h1 className="hidden-block">{headingOne}</h1>
          <h2 className="hidden-block">{headingTwo}</h2>
        </div>
      </div>
      <div className="gnx-mobile-wrapper">
        <div className="image-wrapper">
          <img src={mobileImage} alt="GokenNIHON header image" />
        </div>
      </div>
    </div>
  );
};

export default PageHeader;
