import { Link } from "gatsby";
import React, { useState } from "react";
import arrowImg from "../../images/gokenNihon/arrow.png";

const GNXServices = ({
  heading,
  jaHeading,
  servicesList = [].link,
  linkText,
  link,
}) => {
  const [active, setActive] = useState(-1);
  const onChangeHandler = (number) => {
    setActive(number);
  };
  return (
    <div className="gnx_services-page-wrapper section-br ">
      <div className="gnx__services-wrapper goken-gray-text">
        <div className="heading-wrapper-services w-90-wrapper">
          <h2 className="semi-page-heading extra-bold titlecase-text ">
            {heading} {jaHeading}
          </h2>
          <div className="red-underline"></div>
        </div>
        <div className="services-carousel-wrapper desktop-wrapper w-90-wrapper">
          <div className="about-services-left-wrapper">
            {servicesList?.map(
              (
                { heading, paragraph, mobilePara, linkText, link, image },
                index
              ) => {
                return (
                  <div
                    className={`content-wrapper ${
                      active === index ? "active-content-wrapper" : ""
                    }`}
                    onMouseOver={() => onChangeHandler(index)}
                    onMouseLeave={() => {
                      onChangeHandler(-1);
                    }}
                  >
                    <div className="left-image-wrapper heading-hover">
                      <div className={`gnx-services-heading `}>
                        <h3
                          className="fw-700 margin-0 heading titlecase-text"
                          dangerouslySetInnerHTML={{ __html: heading }}
                        ></h3>
                      </div>
                      <div className="desktop-service-image services-free-image-wrapper">
                        <img src={image} alt="" />
                        <div className={`hover-content-wrapper`}>
                          <p className="semi-para fw-400 desktop-service-image margin-0">
                            {paragraph}
                          </p>
                          {/* <p className="para fw-400 line-height-2 mobile-service-image">
                            {mobilePara}
                          </p> */}
                          <Link to={link} className="color-ja bold">
                            {linkText}
                          </Link>
                        </div>
                        {/* {!(active === index) && (
                          <>
                            <div className="arrow-image">
                              <img
                                className="arrow-img"
                                src={arrowImg}
                                alt=""
                              />
                            </div>
                            <h3 className="title service-heading">{heading}</h3>
                          </>
                        )} */}
                      </div>
                    </div>
                  </div>
                );
              }
            )}
            {/* <div className="services-dots-wrapper">
              {servicesList?.map((service, index) => {
                return (
                  <div
                    className={`${
                      index === active ? "active-ja-dot" : "inactive-ja-dot"
                    }`}
                    onClick={() => {
                      onChangeHandler(index);
                    }}
                  ></div>
                );
              })}
            </div> */}
          </div>
          {/* <div className="about-services-right-wrapper"></div> */}

          {/* <div className="about-services-right-wrapper">
            {servicesList?.map(({ image, mobileImage }, index) => {
              return (
                <div
                  key={index}
                  className={`${index === active ? "active" : "inactive"}`}
                >
                  <div className="desktop-service-image services-free-image-wrapper">
                    <img src={image} alt="" />
                  </div>
                  <div className="mobile-service-image services-free-image-wrapper">
                    <Link to="/contactus/" className="gnx-services-cta-link">
                      <img src={mobileImage} alt="" />
                    </Link>
                  </div>
                </div>
              );
            })}
          </div> */}
        </div>
        <div className="services-mobile-wrapper mobile-view">
          {servicesList?.map(({ paragraph, heading, mobileImage }) => {
            return (
              <div className="services-wrapper section-br">
                <img
                  src={mobileImage}
                  className="services-mobile-image"
                  alt="goken nihon services"
                />
                <div className="mobile-content-wrapper">
                  <h2
                    className="heading"
                    dangerouslySetInnerHTML={{ __html: heading }}
                  ></h2>
                  <p className="para">{paragraph}</p>
                </div>
              </div>
            );
          })}
        </div>
        {/* {servicesList.map(
            (
              {
                heading,
                paragraph,
                link,
                linkText,
                image,
                mobileImage,
                mobilePara,
              },
              index
            ) => {
              return (
                <div
                  className={`gnx__service-block ${
                    index === active
                      ? "gnx_service-visible"
                      : "gnx_service-hidden"
                  }`}
                >
                  <div className="content-wrapper">
                    <h3 className="heading titlecase-text gnx-services-heading">
                      {heading}
                    </h3>
                    <p className="para fw-400 desktop-service-image">
                      {paragraph}
                    </p>
                    <p className="para fw-400 line-height-2 mobile-service-image">
                      {mobilePara}
                    </p>
                    <Link to={link} className="color-ja bold">
                      {linkText}
                    </Link>
                  </div>
                  <div className="desktop-service-image services-free-image-wrapper">
                    <img src={image} alt="" />
                  </div>
                  <div className="mobile-service-image services-free-image-wrapper">
                    <Link to="/contactus/" className="gnx-services-cta-link">
                      <img src={mobileImage} alt="" />
                    </Link>
                  </div>
                </div>
              );
            }
          )} */}
      </div>
      <div className="services-cta-wrapper">
        <Link to={link}>
          <div className="btn-container ">
            <button className={`primary-btn bg-ja`}>{linkText}</button>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default GNXServices;
