import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CustomButtons from "../homepage/homepageAboutSlider/CustomButtons";
import backImage from "../../images/gokenNihon/background.png";
// import leftArrja from "../../images/servicesassets/leftArrow-ja.png";
// import rightArrja from "../../images/servicesassets/rightArrow-ja.png";
const EmployeeTestimonial = ({
  heading,
  testimonialList = [],
  leftArrImg,
  rightArrImg,
}) => {
  const currentLanguage = "ja";
  console.log("testimonials list are ===========>", testimonialList);
  const responsive = {
    desktop: {
      breakpoint: { max: 10000, min: 1024 },
      items: 4,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 713 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 714, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };
  try {
    return (
      <div className={`gnx__employee-testimonial-page`}>
        <img src={backImage} className="background-image" alt="" />
        {/* <div className="w-90-wrapper"> */}
        <div className="w-90-wrapper">
          <h2 className="semi-page-heading ">{heading}</h2>
          <div className="red-underline"></div>
        </div>

        <div
          className={`homepage-about-slider-wrapper slider-wrapper section-br p-bottom-3em  gnx__testimonials-main-container slider-btns-container`}
        >
          <div className={`gnx__testimonial-wrapper`}>
            <Carousel
              responsive={responsive}
              arrows={false}
              renderButtonGroupOutside={true}
              autoPlaySpeed={5000}
              // autoPlay={true}
              ssr
              infinite
              customButtonGroup={
                <CustomButtons
                  show={testimonialList.length > 3}
                  leftArr={rightArrImg}
                  rightArr={leftArrImg}
                  className={"left-10"}
                />
              }
            >
              {testimonialList?.map((testimonial, index) => {
                return (
                  <div
                    className="gnx__testimonial goken-gray-text"
                    index={index}
                  >
                    <div className="content-wrapper-testimonial">
                      <div className="name-wrapper">
                        <h3 className="semi-title fw-700 margin-0">
                          {testimonial?.name}
                        </h3>
                        <h4 className="semi-para fw-400 margin-0">
                          {testimonial?.designation}
                        </h4>
                        <div className="redline-wrapper"></div>
                      </div>
                      <div className="paragraph-wrapper">
                        <>
                          <p className="semi-para fw-400">
                            {testimonial?.paragraph1}
                          </p>
                        </>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
      // </div>
    );
  } catch (error) {
    console.log(error);
  }
};

export default EmployeeTestimonial;
