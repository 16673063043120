import React from "react";

const FundamentalBeliefs = ({
  heading,
  redHeading1,
  redHeading2,
  redHeading3,
  paragraph,
  beliefsList = [],
}) => {
  return (
    <div className="gnx__fundamentals-beliefs-wrapper pad-bottom-3rem w-80-wrapper goken-gray-text section-br">
      {/* <div className="headings">
        <h2 className="semi-page-heading extra-bold titlecase-text margin-0">
          {heading}
        </h2>
        <p className="para">{paragraph}</p>
      </div> */}
      <div className="beliefs-blocks-wrapper">
        {beliefsList.map((belief) => {
          return (
            <div className="belief-block-wrapper">
              <div className="belief-image-wrapper">
                <img src={belief.image} alt="" />
              </div>
              <div className="belief-content-wrapper">
                <h3 className="semi-heading fw-700">{belief.title}</h3>
                <p className="para">{belief.paragraph}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FundamentalBeliefs;
